// <!-- ENUMS -->
import { TemperatureScale, Unit } from '@/enums';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useAgGrid } from '@/hooks/useAgGrid';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import { Enum } from '@/utils/enums';
import {
    formatInteger,
    formatDecimal,
    formatPercent,
} from '@/utils/formatters';

// <!-- MODELS -->
import { ECNBState } from '@/store/types/ECNBStore';

// <!-- TYPES -->
/** @typedef {import('vuex').Store<ECNBState>} Store */
/** @typedef {import('./useNARAStandardsQuery').LocationDistributionsResource} LocationDistributionsResource */

/** @typedef {Combine<{ id: integer, name: string, path: string, label?: string, minDate?: Date, maxDate?: Date, standard?: string, start_date?: string, end_date?: string } & Pick<LocationDistributionsResource['standard'], | 'min_temp' | 'max_temp' | 'min_rh' | 'max_rh'> & LocationDistributionsResource['metrics']>} NARAStandardsRowData */

/**
 * @typedef UseNARAStandardsGridOptions
 * @prop {Store} [store] Track an existing store reference, if it was already provisioned.
 * @prop {NARAStandardsRowData[] | Vue.Ref<NARAStandardsRowData[]>} [data] Possibly reactive initial data.
 * @prop {import('@/hooks/grid/useAgGridPagination').UseAgGridPaginationOptions} [pagination]
 * @prop {string | Vue.Ref<string>} [domLayout] Possibly reactive value controlling DOM layout of the grid.
 */

/**
 * @typedef UseNARAStandardsGridReturnPartial
 * @prop {Vue.Ref<NARAStandardsRowData[]>} rowData Reactive reference used to track the grid row data.
 * @prop {Readonly<AgGrid.ColumnDef<NARAStandardsRowData>>} defaultColumnDef Default column definition.
 * @prop {Readonly<AgGrid.ColumnDef<NARAStandardsRowData>[]>} columnDefs Ordered array of column definitions.
 * @prop {Readonly<Vue.Ref<boolean>> | Vue.ComputedRef<boolean>} isEmpty Is computed metrics collection empty?
 * @prop {(data: Vue.Ref<NARAStandardsRowData[]> | NARAStandardsRowData[]) => void} setRowData Update the reactive `rowData` reference.
 * @prop {import('@vueuse/core').EventHookOn<AgGrid.Events.GridReadyEvent>} onGridReady Fires when the grid is ready.
 * @prop {import('@vueuse/core').EventHookOn<AgGrid.Events.ColumnResizedEvent>} onColumnResized Fires when a grid column is resized.
 */

/**
 * @typedef {UseNARAStandardsGridReturnPartial & ReturnType<useAgGrid<NARAStandardsRowData>>} UseNARAStandardsGridReturn
 */

/**
 * Define the grid composable.
 * @param {UseNARAStandardsGridOptions} [props]
 * @return {UseNARAStandardsGridReturn}
 */
export const useNARAStandardsGrid = (props = {}) => {
    // DESTRUCTURE services.
    const { formatters } = useServices(props);

    /** DEFINE column field names. */
    const ColumnFields = Enum.create({
        id: 'id',
        name: 'name',
        path: 'path',
        label: 'label',
        minDate: 'minDate',
        maxDate: 'maxDate',
        dateRange: 'dateRange',
        startDate: 'start_date',
        endDate: 'end_date',
        standard: 'standard',
        min_temp: 'min_temp',
        max_temp: 'max_temp',
        min_rh: 'min_rh',
        max_rh: 'max_rh',
        records: 'records',
        temp_above_count: 'temp_above_count',
        temp_between_count: 'temp_in_count',
        temp_below_count: 'temp_below_count',
        temp_above_percent: 'temp_above_percent',
        temp_between_percent: 'temp_in_percent',
        temp_below_percent: 'temp_below_percent',
        rh_above_count: 'rh_above_count',
        rh_between_count: 'rh_in_count',
        rh_below_count: 'rh_below_count',
        rh_above_percent: 'rh_above_percent',
        rh_between_percent: 'rh_in_percent',
        rh_below_percent: 'rh_below_percent',
    });

    /**
     * @type {AgGrid.ColumnDef<NARAStandardsRowData>}
     * Default column definition.
     */
    const defaultColumnDef = Object.freeze({
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressMovable: true,
        suppressMenu: true,
        lockPosition: true,
        minWidth: 80,
        flex: 1,
        cellClass: 'flex items-center justify-center leading-5 break-normal',
        // cellClass: 'leading-5 py-2 break-normal',
        headerClass: 'whitespace-normal text-center',
        wrapHeaderText: true,
    });

    // DEFINE the column schema.

    /** @type {Readonly<Partial<Record<keyof ColumnFields, { field: ColumnFields[keyof ColumnFields] } & AgGrid.ColumnDef>>>} */
    const ColumnSchema = Object.freeze({
        [ColumnFields.id]: {
            headerName: '',
            field: ColumnFields.id,
            maxWidth: 50,
        },
        [ColumnFields.name]: {
            headerName: 'Location',
            field: ColumnFields.name,
            suppressSizeToFit: false,
            minWidth: 220,
            flex: 8,
            // cellClass: 'flex items-center justify-left leading-5 break-normal',
        },
        [ColumnFields.label]: {
            headerName: 'Location',
            field: ColumnFields.label,
            suppressSizeToFit: false,
            minWidth: 325,
            wrapText: true,
            autoHeight: true,
            flex: 4,
            cellClass: 'flex items-center justify-left leading-5 break-normal',
        },
        [ColumnFields.dateRange]: {
            headerName: 'Date Range',
            field: `${ColumnFields.startDate}&${ColumnFields.endDate}`,
            valueGetter: formatters.useDateRangeFormat,
            minWidth: 210,
            suppressSizeToFit: false,
            wrapText: true,
            autoHeight: true,
            flex: 2,
        },
        [ColumnFields.standard]: {
            headerName: `Standard`,
            field: ColumnFields.standard,
            suppressSizeToFit: false,
            wrapText: true,
            autoHeight: true,
            flex: 2,
            cellClass: 'flex items-center justify-left leading-5 break-normal',
        },
        [ColumnFields.temp_above_count]: {
            headerName: `${formatters.formatTemperatureHeader(
                'Above (#)',
                'T'
            )}`,
            field: ColumnFields.temp_above_count,
            suppressSizeToFit: false,
            valueFormatter: formatters.useIntegerFormat,
        },
        [ColumnFields.temp_between_count]: {
            headerName: `${formatters.formatTemperatureHeader(
                'Within (#)',
                'T'
            )}`,
            field: ColumnFields.temp_between_count,
            suppressSizeToFit: false,
            valueFormatter: formatters.useIntegerFormat,
        },
        [ColumnFields.temp_below_count]: {
            headerName: `${formatters.formatTemperatureHeader(
                'Below (#)',
                'T'
            )}`,
            field: ColumnFields.temp_below_count,
            suppressSizeToFit: false,
            valueFormatter: formatters.useIntegerFormat,
        },
        [ColumnFields.temp_above_percent]: {
            headerName: `${formatters.formatTemperatureHeader(
                'Above (%)',
                'T'
            )}`,
            field: ColumnFields.temp_above_percent,
            suppressSizeToFit: false,
            valueFormatter: formatters.usePercentFormat,
        },
        [ColumnFields.temp_between_percent]: {
            headerName: `${formatters.formatTemperatureHeader(
                'Within (%)',
                'T'
            )}`,
            field: ColumnFields.temp_between_percent,
            suppressSizeToFit: false,
            valueFormatter: formatters.usePercentFormat,
        },
        [ColumnFields.temp_below_percent]: {
            headerName: `${formatters.formatTemperatureHeader(
                'Below (%)',
                'T'
            )}`,
            field: ColumnFields.temp_below_percent,
            suppressSizeToFit: false,
            valueFormatter: formatters.usePercentFormat,
        },
        [ColumnFields.rh_above_count]: {
            headerName: `${formatters.formatPercentHeader('Above (#)', 'RH')}`,
            field: ColumnFields.rh_above_count,
            suppressSizeToFit: false,
            valueFormatter: formatters.useIntegerFormat,
        },
        [ColumnFields.rh_between_count]: {
            headerName: `${formatters.formatPercentHeader('Within (#)', 'RH')}`,
            field: ColumnFields.rh_between_count,
            suppressSizeToFit: false,
            valueFormatter: formatters.useIntegerFormat,
        },
        [ColumnFields.rh_below_count]: {
            headerName: `${formatters.formatPercentHeader('Below (#)', 'RH')}`,
            field: ColumnFields.rh_below_count,
            suppressSizeToFit: false,
            valueFormatter: formatters.useIntegerFormat,
        },
        [ColumnFields.rh_above_percent]: {
            headerName: `${formatters.formatPercentHeader('Above (%)', 'RH')}`,
            field: ColumnFields.rh_above_percent,
            suppressSizeToFit: false,
            valueFormatter: formatters.usePercentFormat,
        },
        [ColumnFields.rh_between_percent]: {
            headerName: `${formatters.formatPercentHeader('Within (%)', 'RH')}`,
            field: ColumnFields.rh_between_percent,
            suppressSizeToFit: false,
            valueFormatter: formatters.usePercentFormat,
        },
        [ColumnFields.rh_below_percent]: {
            headerName: `${formatters.formatPercentHeader('Below (%)', 'RH')}`,
            field: ColumnFields.rh_below_percent,
            suppressSizeToFit: false,
            valueFormatter: formatters.usePercentFormat,
        },
    });

    // DEFINE grid behaviour.
    const grid = useAgGrid({
        ...props,
        columnSchema: ColumnSchema,
        defaultColumnDef,
    });

    /**
     * @type {ColumnDef<NARAStandardsRowData>[]}
     * Ordered column definition array.
     */
    const columnDefs = grid.getColumnDefs([
        ColumnFields.label,
        ColumnFields.dateRange,
        ColumnFields.standard,
        ColumnFields.temp_above_percent,
        ColumnFields.temp_between_percent,
        ColumnFields.temp_below_percent,
        ColumnFields.rh_above_percent,
        ColumnFields.rh_between_percent,
        ColumnFields.rh_below_percent,
    ]);

    // EXPOSE
    return {
        ...grid,
        defaultColumnDef,
        columnDefs,
    };
};

/**
 * Define the services used by this composable.
 * @param {Pick<UseNARAStandardsGridOptions, 'store'>} [props] @see {@link UseNARAStandardsGridOptions}
 */
const useServices = (props = {}) => {
    // DEFINE the store.
    const store = props?.store ?? useStore();
    // DEFINE formatters.
    const formatters = Object.freeze({
        /**
         * Get the account's current temperature unit.
         * @returns {Unit['Celsius'] | Unit['Fahrenheit'] | Unit['Unknown']}
         */
        getTemperatureUnit: () => {
            const temperatureScale =
                store.state.accounts.account.temperatureScale;
            switch (temperatureScale) {
                case TemperatureScale.Celsius:
                    return Unit.Celsius;
                case TemperatureScale.Fahrenheit:
                    return Unit.Fahrenheit;
                default:
                    return Unit.Unknown;
            }
        },
        /**
         * Value formatter used to format the date range.
         * @param {Omit<Parameters<AgGrid.ValueGetterFunc>, 'data'> & { data: NARAStandardsRowData }} params
         */
        useDateRangeFormat: (params) => {
            const {
                start_date = '',
                end_date = '',
                standard = null,
            } = params?.data ?? {};
            const isStartValid =
                is.nonEmptyStringAndNotWhitespace(standard) &&
                !is.nullOrUndefined(start_date) &&
                !is.emptyStringOrWhitespace(start_date);
            const isEndValid =
                is.nonEmptyStringAndNotWhitespace(standard) &&
                !is.nullOrUndefined(end_date) &&
                !is.emptyStringOrWhitespace(end_date);
            if (isStartValid && isEndValid) {
                return `${start_date} - ${end_date}`;
            }
            return `No Data Available`;
        },
        /**
         * Format the decimal value into a temperature string.
         * @type {AgGrid.ValueFormatterFunc}
         */
        useIntegerFormat: (params) => {
            const value =
                is.nullOrUndefined(params?.value) || params?.value === ''
                    ? NaN
                    : Number(params?.value);
            /** @type {Pick<Intl.NumberFormatOptions, 'minimumIntegerDigits'>} */
            const options = { minimumIntegerDigits: 1 };
            const formatted = formatInteger({ value, options });
            return formatted === '' ? '--' : formatted;
        },
        /**
         * Format the decimal value into a percent string.
         * @type {AgGrid.ValueFormatterFunc}
         */
        usePercentFormat: (params) => {
            const value =
                is.nullOrUndefined(params?.value) || params?.value === ''
                    ? NaN
                    : Number(params?.value);
            /** @type {Pick<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>} */
            const options = {};
            const formatted = formatPercent({ value, options });
            return formatted === '' ? '--' : formatted;
        },
        /**
         * Format the decimal value into a percent string.
         * @param {AgGrid.ValueFormatterParams} params
         * @param {Pick<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>} [options]
         */
        useDecimalFormat: (params, options = {}) => {
            const value =
                is.nullOrUndefined(params?.value) || params?.value === ''
                    ? NaN
                    : Number(params?.value);
            const formatted = formatDecimal({ value, options });
            return formatted === '' ? '--' : formatted;
        },
        /**
         * Format header label with unit.
         * @param {String} label Header label to format.
         * @param {String} tag Tag to use.
         * @param {String} unit Unit to use.
         * @returns {String}
         */
        formatMetricLabel: (label, tag, unit) => {
            return `${tag}${unit} ${label}`.trimEnd();
        },
        /**
         * Format header label with unit.
         * @param {String} label Header label to format.
         * @param {String} tag Tag to use.
         * @returns {String}
         */
        formatTemperatureHeader: (label, tag) => {
            const unit = formatters.getTemperatureUnit();
            return `${tag}${unit} ${label}`.trimEnd();
        },
        /**
         * Format header label with unit.
         * @param {String} label Header label to format.
         * @param {String} tag Tag to use.
         * @returns {String}
         */
        formatPercentHeader: (label, tag) => {
            const unit = Unit.Percent;
            return `${unit}${tag} ${label}`.trimEnd();
        },
    });

    // EXPOSE services.
    return {
        store,
        formatters,
    };
};
